import styled from 'styled-components';

export const Logo = styled.div`
  width: 252px;
  margin-bottom: 80px;
`;

export const Main = styled.main`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background.dark};
`;

export const GradientCircleTopRight = styled.div`
  position: absolute;
  top: -347px;
  right: -174px;
  z-index: 1;
  filter: blur(128px);
  width: 1068px;
  height: 1068px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(28, 70, 133, 0.5) 0%,
    rgba(28, 70, 133, 0) 100%
  );
`;

export const GradientCircleBottom = styled.div`
  position: absolute;
  top: 660px;
  left: calc(50% - 728px / 2 - 1px);
  z-index: 1;
  opacity: 0.5;
  filter: blur(128px);
  width: 728px;
  height: 728px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(28, 70, 133, 0.5) 0%,
    rgba(28, 70, 133, 0) 100%
  );
`;

export const PageContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 453px;
  height: 100%;
  margin: auto;
  padding-top: 100px;
`;

export const HeadingWrapper = styled.div`
  margin-bottom: 8px;
`;

export const ParagraphWrapper = styled.div`
  margin-bottom: 40px;
`;
