import { UserRole } from '@/core/interfaces/common';

export enum TokenTypes {
  ACCESS = 'access',
  REFRESH = 'refresh',
}

export enum AuthType {
  CREDENTIALS = 'credentials',
}

export type Token = TokenTypes.REFRESH | TokenTypes.ACCESS;

export interface JWTPayload {
  // eslint-disable-next-line camelcase
  token_type: Token;
  exp: number;
  jti: string;
  // eslint-disable-next-line camelcase
  user_id: number;
}

export type LoginRequestPayload = {
  [TokenTypes.ACCESS]: string;
  [TokenTypes.REFRESH]: string;
};

export interface RefreshTokenPayload {
  access: string;
  refresh: string;
}

export interface CreateTokenPayload {
  email: string;
  tokens: {
    access: string;
    refresh: string;
  };
}

export type ForgotPasswordRequestPayload = {
  email: string;
};

export type ForgotResetResponseError = {
  error: string;
};

export type ResetPasswordRequestQueryParams = {
  token: string;
  uidb64: string;
};

export type ResetPasswordRequestPayload = {
  password: string;
  confirmPassword: string;
};

export type ResetPasswordResponseError = {
  password?: Array<string>;
  confirmPassword?: Array<string>;
  nonFieldErrors?: Array<string>;
};

export type UserListItem = {
  email: string;
  firstName: string;
  groups: Array<UserRole>;
  id: string;
  lastName: string;
};

export type OrganizationUsersResponse = Array<UserListItem>;

export type UserUpdateRequestData = {
  profilePicture?: File;
};

export type UserUpdateResponse = {
  profilePicture: string;
};

export type ChangePasswordRequestData = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export type ChangePasswordErrorResponse = {
  formErrors: {
    oldPassword: { oldPassword: string };
    newPassword: Array<string>;
  };
};

export type UpdateOrganizationUserRoleData = {
  role: UserRole;
};
