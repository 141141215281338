import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useAppDispatch } from '@/core/store/store';
import { LoginStatesValues } from '@/core/constants/constants';

import { getUser } from '@/features/Auth/store';
import { AuthType } from '@/features/Auth/interfaces';
import { FormDataType, LoginForm } from '@/features/Auth/components/LoginForm';
import { AuthLayout } from '@/features/Auth/components/AuthLayout';

import { request } from '@/utils/request';

const schema = yup.object().shape({
  email: yup.string().email().required('Email is a required field'),
  password: yup.string().required('Password is a required field'),
});

export const Login = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { data: sessionData, status } = useSession();

  const [formError, setFormError] = React.useState<string | null>(null);

  const formMethods = useForm<FormDataType>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const { setError } = formMethods;

  const onSubmit = async (formData: FormDataType) => {
    setFormError(null);

    try {
      const res = await signIn<AuthType.CREDENTIALS>(AuthType.CREDENTIALS, {
        ...formData,
        redirect: false,
        callbackUrl: '/',
      });

      if (res?.error) {
        setFormError(res.error);

        const errorJson = JSON.parse(res.error);

        if (errorJson.password) {
          let passwordError = errorJson.password;

          if (Array.isArray(passwordError)) {
            passwordError = passwordError.join(' ');
          }

          setError('password', { message: passwordError });
        }

        if (errorJson.email) {
          let emailError = errorJson.email;

          if (Array.isArray(emailError)) {
            emailError = emailError.join(' ');
          }

          setError('email', { message: emailError });
        }

        setFormError(errorJson.detail);

        return;
      }

      return res;
    } catch (error) {
      setFormError('Something went wrong. Please try again.');

      return null;
    }
  };

  useEffect(() => {
    const afterLogin = async () => {
      if (status === LoginStatesValues.AUTHENTICATED && sessionData?.accessToken) {
        request.setAuthorizationToken(sessionData?.accessToken);
        await dispatch(getUser());

        router.replace('/');
      }
    };

    afterLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <AuthLayout
      title="Welcome to Hozint"
      subtitle="Please login to proceed"
    >
      <FormProvider {...formMethods}>
        <LoginForm
          onSubmit={onSubmit}
          formError={formError}
        />
      </FormProvider>
    </AuthLayout>
  );
};
