import NextImage from 'next/image';
import NextLink from 'next/link';
import { useTheme } from 'styled-components';

import { ThemeVariant } from '@/core/interfaces/common';
import { Heading, Subheadline } from '@/core/components/Typography';

import {
  Main,
  Logo,
  PageContent,
  GradientCircleTopRight,
  GradientCircleBottom,
  HeadingWrapper,
  ParagraphWrapper,
} from '@/features/Auth/components/AuthLayout/AuthLayout.styles';

import { AuthLayoutProps } from './AuthLayout.types';

export const AuthLayout = ({ title, subtitle, children }: AuthLayoutProps) => {
  const { theme, colors } = useTheme();

  return (
    <Main>
      <GradientCircleTopRight />
      <GradientCircleBottom />
      <PageContent>
        <Logo>
          {theme === ThemeVariant.DARK ? (
            <NextLink href="/">
              <NextImage
                src="/assets/logo-dark.svg"
                alt="Dark App Logo"
                width={140}
                height={42}
              />
            </NextLink>
          ) : (
            <NextLink href="/">
              <NextImage
                src="/assets/logo-light.svg"
                alt="Light App Logo"
                width={140}
                height={42}
              />
            </NextLink>
          )}
        </Logo>
        <HeadingWrapper>
          <Heading type="h1">{title}</Heading>
        </HeadingWrapper>
        <ParagraphWrapper>
          <Subheadline
            type="normal"
            color={colors.secondaryText}
          >
            {subtitle}
          </Subheadline>
        </ParagraphWrapper>
        {children}
      </PageContent>
    </Main>
  );
};
